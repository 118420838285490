<template>
  <div :id="viewCSSId" :class="viewCSSClasses" ref="scene">
    <div class="section-foreground container">
      <div class="layout-column-rows-layout-align-start-center">
        <div class="row row-1 layout-column layout-align-center-center">
          <section-title
            :partNo="part.id"
            :partTitle="part.title"
            data-depth="2.5"
            class="text-right parallax-item parallax-relative single-line"
            :show="showTitle"
          ></section-title>
        </div>
        <div class="row row-2 layout-row cols">
          <div
            class="
              col col-1
              layout-column
              rows
              layout-align-start-end
              col-padding
            "
          >
            <div class="row layout-column layout-align-start-end row-1-1">
              <lead
                :show="showLead"
                class="parallax-item parallax-relative"
                data-depth="1.8"
                :timescale="0.9"
                >{{ data.meta.description }}</lead
              >
            </div>
            <div class="row layout-row layout-align-end-start row-1-2">
              <video-box
                id="video-2020"
                class="parallax-item section-videobox parallax-relative"
                data-depth="2.2"
                :show="showVideoBox"
                :data="data.boxes.video"
                :timescale="0.95"
                :timescaleReverse="1.5"
              >
              </video-box>
              <info-modal-box
                id="intro-box"
                :show="showInfoBoxes"
                class="parallax-item parallax-relative section-infobox"
                data-depth="3.6"
                :data="data.boxes.info_1"
                :timescale="1"
                :headingDepth="0.1"
                :headingWidth="'55%'"
              >
              </info-modal-box>
            </div>
          </div>
          <div
            class="col col-2 layout-align-start-start layout-column col-padding"
          >
            <quote
              :data="data.boxes.quote"
              :fontSize="1.6"
              :maxWidth="'20.5em'"
              :overlayOpacity="0.55"
              :show="showQuote"
              data-depth="3"
              :timescale="0.7"
              :splitLines="true"
            >
            </quote>
          </div>
        </div>
      </div>
    </div>

    <background-image-full
      :image="data.images.background"
      :show="showBackground"
      :imageCss="{ objectPosition: 'center right', right: '-9%', top: '-1.5%' }"
      :mask="true"
      :maskOpacity="0.3"
      :timescaleReverse="2.5"
      :timescale="1.17"
    ></background-image-full>
    <div class="container absolute fluid container-birds">
      <ink
        class="absolute"
        :show="showInk"
        :customTimeline="timelineInk"
        :image="data.images.ink"
        :parallaxDepth="1"
      ></ink>
      <decal
        id="stork1"
        class="absolute"
        :show="showBirds"
        :image="data.images.stork1"
        :customTimeline="timelineStork1"
        :parallaxDepth="2.4"
      ></decal>

      <decal
        id="stork2"
        class="absolute"
        :show="showBirds"
        :image="data.images.stork2"
        :parallaxDepth="2"
        :customTimeline="timelineStork1"
      ></decal>
    </div>

    <div class="container absolute">
      <decal
        id="giraffe"
        class="absolute"
        :customTimeline="timelineGiraffe"
        :show="showDecal"
        :parallaxDepth="2.5"
        :image="data.images.decal"
      ></decal>
    </div>

    <modal-main
      v-for="modal in data.modals"
      :data="modal"
      :key="modal.name"
    ></modal-main>
    <modal-lightbox></modal-lightbox>
    <modal-video></modal-video>
  </div>
</template>

<script>
import { chapterPart } from "@/mixins/core.js";

import SectionTitle from "@/components/layout/SectionTitle.vue";
import BackgroundImageFull from "@/components/ui/BackgroundImageFull.vue";
import Quote from "@/components/ui/Quote.vue";
import Decal from "@/components/ui/Decal.vue";
import Ink from "@/components/ui/Ink.vue";
import Lead from "@/components/ui/Lead.vue";
import InfoModalBox from "@/components/ui/InfoModalBox.vue";
import ModalLightbox from "@/components/ui/ModalLightbox.vue";
import ModalVideo from "@/components/ui/ModalVideo.vue";
import VideoBox from "@/components/ui/VideoBox.vue";
import ModalMain from "@/modals/ModalMain.vue";

export default {
  name: "Chapter-1-1",
  mixins: [chapterPart],
  components: {
    SectionTitle,
    BackgroundImageFull,
    Quote,
    Decal,
    Ink,
    Lead,
    InfoModalBox,
    ModalLightbox,
    ModalVideo,
    VideoBox,
    ModalMain,
  },
  data() {
    return {
      doParallax: true,
      showBirds: false,
      itemsToShow: [
        ["showDelay", 0],
        ["showBackground", 600],
        ["showTitle", 500],
        ["showQuote", 150],
        ["showBirds", 250],
        ["showInfoBoxes", 400],
        ["showInk", 0],
        ["showDecal", 50],
        ["showVideoBox", 250],
        ["showLead", 1200],
      ],
      timelineStork1: {
        duration: 2.1,
        animations: {
          ease: "Power3.easeInOut",
          autoAlpha: 0,
          scale: 0.2,
          left: "-=20%",
          top: "+=40%",
        },
      },
      timelineGiraffe: {
        duration: 1.2,
        animations: {
          ease: "Power1.easeInOut",
          autoAlpha: 0,
          y: "+=17%",
          x: "+=7%",
          scale: "0.9",
        },
      },
      timelineInk: {
        duration: 1.2,
        animations: {
          scale: 1,
          x: "-=2%",
          ease: "Power2.easeInOut",
        },
      },
    };
  },
  watch: {},
  methods: {},
};
</script>

<style scoped>
.row-1 {
  margin-bottom: 0.9rem;
  margin-top: 1rem;
}
.row-2 {
  margin-bottom: 0rem;
}
.row-1-2 {
  margin-top: 2.8rem;
}
.col-1 {
  max-width: 100%;
}
.col-1 .row {
  width: 100%;
}
.col-2 {
  max-width: 43.5%;
  margin-top: 2.8rem;
}

.section-title {
  margin-right: 15.8%;
}
.part-lead {
  margin-right: 2%;
}
/deep/ .lead {
  max-width: 37em;
}

#intro-box {
  margin-right: 17.4%;
}
#video-2020 {
  margin-top: 5rem;
  margin-right: -1.8rem;
}
/deep/ #intro-box h3 span,
/deep/ #video-2020 h4 span {
  width: 60%;
}
/deep/ .video-box-image img {
  filter: grayscale(0.7);
}

#stork1 {
  top: 7.029em;
  left: -1.438em;
  width: 13.75em;
  overflow: visible;
  opacity: 0.9;
}
#stork2 {
  top: 15.662em;
  left: 2.625em;
  width: 10.625em;
  overflow: visible;
  opacity: 0.9;
}
.section-decal {
  margin: 0;
  bottom: -1.5em;
  max-width: 780px;
  left: 36%;
}
#giraffe {
  min-width: 340px;
}
.section-ink {
  left: -22.188em !important;
  top: 6.771em !important;
  opacity: 0.4;
}
/deep/ .section-ink img {
  filter: hue-rotate(355deg) brightness(105%);
}
/deep/ .section-ink img {
  max-width: 50.25em;
}
.section-background {
}
/deep/ .section-background-img img {
  right: -22.688em !important;
  left: auto !important;
  top: -3.5% !important;
  width: auto;
  height: 106%;
}

/*=============================================
=                  MEDIA QUERIES             =
=============================================*/

@media screen and (max-width: 1600px) {
  .section-decal {
    left: 36%;
    max-width: 45vw;
  }
  .section-quote {
    font-size: 0.9rem;
  }
  .col-2 {
    padding-left: 0;
    max-width: 40%;
  }
}

@media screen and (max-width: 1440px) {
  .col-2 {
    margin-top: 2.4rem;
  }
  #video-2020 {
    margin-top: 4rem;
  }
}

@media screen and (max-width: 1366px) {
  .container-birds {
    left: -5%;
    font-size: 1rem;
  }
  .col-1 {
    padding-right: 0;
  }
  .section-decal {
    left: 40%;
  }
  .col-2 {
    max-width: 39%;
  }
  #video-2020 {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 1200px) {
  .container-birds {
    left: -10%;
    font-size: 0.9rem;
  }
  /deep/ .section-quote blockquote {
    max-width: 17em !important;
  }
  .row-2 {
    justify-content: center;
    align-content: flex-start;
    align-items: flex-start;
  }
  .col-1 {
    max-width: 100%;
    width: auto;
    flex: 0 1 auto;
    margin-left: 5.3%;
  }
  .col-2 {
    max-width: 100%;
    width: auto;
    flex: 0 1 auto;
  }
  #intro-box {
    margin-right: 8.5%;
  }
  #video-2020 {
    margin-top: 2rem;
    margin-right: -2.8rem;
  }
  .row-2 {
    padding-bottom: 0rem;
  }
  .section-decal {
    left: 45%;
  }
  #giraffe {
    min-width: 500px;
  }
  .section-quote {
    font-size: 0.75rem;
  }
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 1023px),
  screen and (min-width: 992px) and (max-width: 1024px) and (orientation: portrait) {
  .row-2 {
    flex-direction: column;
    padding-bottom: 6rem;
  }
  .row-1-2 {
    margin-top: 1.5rem;
  }
  .col-1 > .row {
    align-items: center;
    justify-content: center;
  }
  .col-1 {
    margin-left: 0;
  }
  .col-1,
  .col-2 {
    padding: var(--col-padding);
    width: 100%;
    max-width: 100%;
  }
  #intro-box {
    margin-right: 0;
  }
  .section-quote {
    align-self: flex-end;
    margin-top: 1rem;
    font-size: 1rem;
    max-width: 60%;
  }
  .part-lead {
    margin-left: 10%;
    margin-right: var(--col-padding-adjust);
  }
  .section-decal {
    left: -5%;
    max-width: 60vw;
  }
  #video-2020 {
    margin-right: -1.8rem;
  }
  #giraffe {
    min-width: 340px;
  }
}

@media screen and (max-width: 991px) {
  .section-quote {
    max-width: 60%;
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 767px) {
  .part-lead {
    align-self: flex-end;
    margin-left: 19%;
  }
  .section-quote {
    max-width: 55%;
    font-size: 0.65rem;
  }
  .col-2 {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 639px) {
  .part-lead {
    max-width: 80%;
    margin-left: 0%;
  }
  .section-title {
    margin-right: 0;
  }
  .row-1 {
    margin-bottom: 0.5rem;
  }
  .col-1 .row {
    flex-direction: column;
  }
  #video-2020 {
    margin: 0;
  }
  .container-birds {
    top: 6%;
    font-size: 10px;
  }
  .part-lead {
    max-width: none;
  }
  .section-quote {
    max-width: 80%;
    align-self: flex-end;
    font-size: 0.7rem;
  }
  /deep/ .section-quote blockquote {
    max-width: 100% !important;
    width: 100% !important;
  }
  .col-1 {
    order: 2;
  }
  .col-2 {
    justify-content: center;
    order: 1;
    margin-top: 0;
    margin-bottom: 2rem;
  }
  .section-decal {
    left: -15%;
    max-width: 80vw;
  }
}

@media screen and (max-width: 520px) {
  .section-ink {
    display: none;
  }
}

@media screen and (max-width: 479px) {
}

@media screen and (max-width: 420px) {
  .section-quote {
    font-size: 0.6rem;
  }
}
</style>
